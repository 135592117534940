import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import demoBackground from "assets/demoBackground.jpg";

import { Button } from "shards-react";
import { FiAlertTriangle, FiPlusCircle } from "react-icons/fi";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { getUserData, getUserRestrictions } from "selectors/user";
import { useDispatch, useSelector } from "react-redux";
import PostSignUp from "nlib/common/PostSignUp";
import React, { useCallback } from "react";
import UiActions from "actions/UiActions";

const NoBusinesses = ({ onAddBusiness }) => {
  const dispatch = useDispatch();

  const { errors, uiTexts, messages } = useSelector(getTextsData);

  const activeOrganization = useSelector(getActiveOrganization);

  const userRestrictions = useSelector(getUserRestrictions);

  const userData = useSelector(getUserData);

  const { currency, countryCode } = activeOrganization || {};

  const [businessCreationGreetingA, businessCreationGreetingB] = messages?.businessCreationGreeting || [];

  const handleAddNewBusinessButtonClick = useCallback(async() => {
    const result = await dispatch(UiActions.showAddBusinessWindow());

    if (result) onAddBusiness(result);
  }, [onAddBusiness, dispatch]);

  if (!currency || !countryCode || userRestrictions.businessesCreate) {
    const [accessDeniedErrorA, accessDeniedErrorB] = errors.accessDenied;

    return (
      <div className={Css.noBusiness}>
        <div className={Css.centered}>
          <h3>
            <FiAlertTriangle className={CommonCss.negativeText} />
            <span>{accessDeniedErrorA}</span>
          </h3>
          <h5>{accessDeniedErrorB}</h5>
        </div>
      </div>
    );
  }

  if ((activeOrganization.billingId && !activeOrganization.subscriptionId) || userData.holdUser) {
    return (
      <div
        className={Css.noBusiness}
        style={{ backgroundImage: `linear-gradient(rgba(0, 49, 102, 0.3), rgba(0, 49, 102, 0.3)), url(${demoBackground})` }}>
        <PostSignUp />
      </div>
    );
  }

  return (
    <div className={Css.noBusiness}>
      <div className={Css.centered}>
        <h2>{businessCreationGreetingA}</h2>
        <h4>{businessCreationGreetingB}</h4>
        <div>
          <Button
            size="sm"
            disabled={userRestrictions.businessesCreate}
            onClick={handleAddNewBusinessButtonClick}>
            <span><FiPlusCircle /><span>{uiTexts.addNewBusiness}</span></span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(NoBusinesses);
