import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import { Col, FormCheckbox, FormGroup, FormSelect, InputGroup, InputGroupAddon, InputGroupText, Row } from "shards-react";
import { FiActivity, FiInfo } from "react-icons/fi";
import { Form, ModalWindow } from "lib/common";
import { getActiveUsersData } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { getUserBusinessesData } from "selectors/businesses";
import { getUserData } from "selectors/user";
import { useDispatch, useSelector } from "react-redux";
import BusinessesTable from "./lib/BusinessesTable";
import Constants from "const/Constants";
import IntegrationServices from "const/IntegrationServices";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import SummaryActions from "actions/SummaryActions";
import UiActions from "actions/UiActions";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import moment from "moment";

const LAST_MONTHS_TO_SHOW = 12;

const ExecutiveSummaryWindow = ({ onClose }) => {
  const dispatch = useDispatch();

  const { uiTexts, messages } = useSelector(getTextsData);

  const userBusinessesData = useSelector(getUserBusinessesData);

  const usersData = useSelector(getActiveUsersData);

  const userData = useSelector(getUserData);

  const dates = useMemo(() => {
    return new Array(LAST_MONTHS_TO_SHOW).fill().map((value, index) => {
      return moment().subtract(index + 1, "month").format(Constants.DATETIME_FORMATS.API_MONTH_DATE);
    });
  }, []);

  const [showSelectedOnly, setShowSelectedOnly] = useState(false);

  const [date, setDate] = useState(dates[0]);

  const [selectedItems, setSelectedItem] = useState({});

  const selectedItemsCount = useMemo(() => Object.values(selectedItems).filter(Boolean).length, [selectedItems]);

  const recipients = useMemo(() => {
    return Object.entries(selectedItems)
      .map(([businessId, emails]) => ({ businessId, emails }))
      .filter(({ emails }) => emails);
  }, [selectedItems]);

  const recipientsValid = useMemo(() => {
    return recipients.length && recipients.every(({ emails }) => emails.length);
  }, [recipients]);

  const tableData = useMemo(() => {
    return userBusinessesData.map((businessData) => {
      return {
        id: businessData.id,
        name: businessData.name,
        emails: usersData.filter((value) => {
          return UserRoles.checkIsBusiness(value.role) && value.businessIds.includes(businessData.id);
        }).map((value) => value.email),
        integrationServiceConnected: businessData.extraData.integrationServiceConnected,
        integrationService: businessData.extraData.integrationService
      };
    }).filter((businessData) => {
      const supportedBusiness = businessData.integrationService === IntegrationServices.QUICK_BOOKS.value;

      const connectedBusiness = businessData.integrationServiceConnected;

      return supportedBusiness && connectedBusiness && (showSelectedOnly ? selectedItems[businessData.id] : true);
    });
  }, [userBusinessesData, usersData, selectedItems, showSelectedOnly]);

  const handleDateSelectorChange = useCallback(({ target: { value } }) => {
    setDate(value);
  }, []);

  const handleTableTestSend = useCallback(async(businessId) => {
    const [executiveSummaryTestConfirmA, executiveSummaryTestConfirmB] = messages.executiveSummaryTestConfirm;

    const businessName = userBusinessesData.find((value) => value.id === businessId).name;

    const result = await dispatch(UiActions.showModal(
      [
        Utils.replaceTextVars(executiveSummaryTestConfirmA, { businessName }),
        Utils.replaceTextVars(executiveSummaryTestConfirmB, { email: userData.email })
      ].join("\n\n"),
      uiTexts.confirm,
      true
    ));

    if (result) {
      dispatch(SummaryActions.sendExecutiveSummary(date, [{ businessId, emails: [userData.email] }], true));
    }
  }, [uiTexts, messages, userBusinessesData, userData, date, dispatch]);

  const handleTableSelectionChange = useCallback((value) => {
    setSelectedItem(value);
  }, []);

  const handleShowSelectedOnlyToggleChange = useCallback(({ target: { checked } }) => {
    setShowSelectedOnly(checked);
  }, []);

  const handleModalClose = useCallback((result) => {
    if (result) {
      dispatch(SummaryActions.sendExecutiveSummary(date, recipients));
    }
    onClose();
  }, [date, recipients, onClose, dispatch]);

  useEffect(() => {
    if (showSelectedOnly && !selectedItemsCount) setShowSelectedOnly(false);
  }, [showSelectedOnly, selectedItemsCount]);

  return (
    <ModalWindow
      className={Css.executiveSummaryWindow}
      disabledOkButton={!recipientsValid}
      config={{
        confirm: true,
        headerText: uiTexts.executiveSummary,
        okButtonText: uiTexts.sendExecutiveSummary
      }}
      iconComponent={FiActivity}
      onClose={handleModalClose}>
      <Form>
        <FormGroup row>
          <Row form>
            <Col>
              <InputGroup className={Css.dateInputGroup}>
                <InputGroupAddon type="prepend">
                  <InputGroupText>{uiTexts.month}</InputGroupText>
                </InputGroupAddon>
                <FormSelect value={date} onChange={handleDateSelectorChange}>
                  {dates.map((value) => (<option key={value} value={value}>
                    {moment(value).format(Constants.DATETIME_FORMATS.MONTH_AND_YEAR_TEXT_EXT)}
                  </option>))}
                </FormSelect>
              </InputGroup>
            </Col>
          </Row>
        </FormGroup>
        <FormGroup row>
          <Row form>
            <Col>
              <div>
                <FiInfo />
                <span>{messages.executiveSummaryNotice}</span>
              </div>
            </Col>
          </Row>
        </FormGroup>
        <FormGroup row>
          <Row form>
            <Col className={Css.tableColumn}>
              {tableData.length
                ? <div className={Css.tableWrapper}>
                  <BusinessesTable
                    className={Css.businessesTable}
                    data={tableData}
                    selectedItems={selectedItems}
                    onSelectionChange={handleTableSelectionChange}
                    onTestSend={handleTableTestSend} />
                </div>
                : <div className={CommonCss.noDataContent}>
                  <div>
                    <div><FiActivity /></div>
                    <div>{uiTexts.noData}</div>
                  </div>
                </div>}
            </Col>
          </Row>
        </FormGroup>
        <FormGroup row>
          <Row form>
            <Col>
              <FormCheckbox
                toggle
                disabled={!selectedItemsCount}
                checked={showSelectedOnly}
                className={Css.selectedOnlyToggle}
                onChange={handleShowSelectedOnlyToggleChange}>
                {uiTexts.showSelectedOnly}
              </FormCheckbox>
            </Col>
            <Col className={Css.selectedItemsColumn}>
              <div>{`${uiTexts.selected}: ${selectedItemsCount}`}</div>
            </Col>
          </Row>
        </FormGroup>
      </Form>
    </ModalWindow>
  );
};

export default React.memo(ExecutiveSummaryWindow);
