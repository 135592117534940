import "lib/index.scss";

import Css from "./style.module.scss";

import { FiMail } from "react-icons/fi";
import { checkAuthState } from "selectors/user";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import GlobalTooltip from "nlib/common/GlobalTooltip";
import ModalsContainer from "./lib/ModalsContainer";
import React, { Suspense } from "react";
import Routes from "./lib/Routes";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";

const App = () => {
  const { pathname } = useLocation();

  const { uiTexts } = useSelector(getTextsData);

  const activeOrganization = useSelector(getActiveOrganization);

  const userAuthState = useSelector(checkAuthState);

  const { PROJECT_NAME, EMAIL_CONTACT, LP_URL } = Utils.getProcessEnvVars();

  return (
    <>
      <div
        className={`${Css.app} app`}>
        <Routes />
        {userAuthState && pathname === UiRoutes.MAIN && <div className={Css.footer}>
          {activeOrganization && activeOrganization.whiteLabel
            ? <a href={LP_URL}><b>{uiTexts.poweredByBookeAi}</b></a>
            : <>
              <a href={LP_URL}><b>{PROJECT_NAME}</b></a>
              <a href={`mailto:${EMAIL_CONTACT}`}><FiMail /><span>{EMAIL_CONTACT}</span></a>
            </>}
        </div>}
        <Suspense fallback={null}>
          <ModalsContainer />
        </Suspense>
      </div>
      <GlobalTooltip />
    </>
  );
};

export default React.memo(App);
