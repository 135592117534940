import CommonCss from "lib/common/style.module.scss";

import TableCss from "lib/common/Table/style.module.scss";

import Css from "./style.module.scss";

import * as Yup from "yup";
import { Button, FormCheckbox } from "shards-react";
import { FiBriefcase, FiMail } from "react-icons/fi";
import { Table, TagsInput } from "lib/common";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import React, { useCallback, useMemo, useState } from "react";
import classNames from "classnames";

const SQUARE_CELL_WIDTH = 50;

const NAME_CELL_WIDTH = 200;

const ACTIONS_CELL_WIDTH = 100;

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string().trim().email()
});

const BusinessesTable = ({
  data,
  selectedItems,
  className,
  onSelectionChange,
  onTestSend
}) => {
  const { uiTexts } = useSelector(getTextsData);

  const selectedItemsCount = useMemo(() => Object.values(selectedItems).filter(Boolean).length, [selectedItems]);

  const initialItemsEmails = useMemo(() => Object.fromEntries(data.map(({ id, emails }) => [id, emails])), [data]);

  const initialEmailInputIds = useMemo(() => Object.fromEntries(data.map(({ id }) => [id, uuid()])), [data]);

  const [itemsEmails, setItemsEmails] = useState(initialItemsEmails);

  const [emailInputIds, setEmailInputIds] = useState(initialEmailInputIds);

  const handleHeaderCheckBoxChange = useCallback(({ target: { checked } }) => {
    onSelectionChange(checked ? Object.fromEntries(data.map(({ id }) => [id, itemsEmails[id] || []])) : {});
  }, [data, itemsEmails, onSelectionChange]);

  const handleCheckBoxChange = useCallback(({ target: { checked, dataset: { id } } }) => {
    const emails = itemsEmails[id] || [];

    onSelectionChange({ ...selectedItems, [id]: checked ? emails : null });
  }, [selectedItems, itemsEmails, onSelectionChange]);

  const handleEmailsInputChange = useCallback(({ target: { value, dataset: { id } } }) => {
    const email = (value[value.length - 1] || "").trim();

    setItemsEmails({ ...itemsEmails, [id]: value });

    if (selectedItems[id]) {
      onSelectionChange({ ...selectedItems, [id]: email && VALIDATION_SCHEMA.isValidSync({ email }) ? value : [] });
    }
  }, [selectedItems, itemsEmails, onSelectionChange]);

  const handleEmailInputBlur = useCallback(({ target: { value, dataset: { id } } }) => {
    setItemsEmails({ ...itemsEmails, [id]: value.filter((tag) => VALIDATION_SCHEMA.isValidSync({ email: tag })) });
    setEmailInputIds({ ...emailInputIds, [id]: uuid() });
  }, [itemsEmails, emailInputIds]);

  const handleTestSendButtonClick = useCallback((event) => {
    const { id } = event.currentTarget.dataset;

    onTestSend(id);
  }, [onTestSend]);

  const tableColumns = useMemo(() => [
    {
      accessor: "id",
      sortable: false,
      width: SQUARE_CELL_WIDTH,
      className: TableCss.squareCell,
      headerClassName: TableCss.squareCell,
      Header: () => {
        return <FormCheckbox checked={data.length === selectedItemsCount} onChange={handleHeaderCheckBoxChange} />;
      },
      Cell: ({ value }) => {
        return <FormCheckbox data-id={value} checked={!!selectedItems[value]} onChange={handleCheckBoxChange} />;
      }
    },
    {
      accessor: "name",
      width: NAME_CELL_WIDTH,
      Header: uiTexts.businessName,
      Cell: ({ value }) => {
        return (
          <span>
            <FiBriefcase />
            <span>{value}</span>
          </span>
        );
      }
    },
    {
      accessor: "emails",
      className: Css.emailsCell,
      Header: uiTexts.emails,
      Cell: ({ value, original: { id } }) => (<TagsInput
        type="email"
        key={emailInputIds[id]}
        data-id={id}
        autoCompleteData={value}
        placeholder={uiTexts.enterEmail}
        className={Css.emailsInputContainer}
        invalid={selectedItems[id] && !selectedItems[id].length}
        value={itemsEmails[id]}
        onChange={handleEmailsInputChange}
        onBlur={handleEmailInputBlur} />)
    },
    {
      width: ACTIONS_CELL_WIDTH,
      sortable: false,
      className: CommonCss.flexEnd,
      Header: uiTexts.actions,
      Cell: ({ original: { id } }) => {
        return (
          <Button data-id={id} size="sm" theme="info" onClick={handleTestSendButtonClick}>
            <FiMail />
            <span>{uiTexts.test}</span>
          </Button>
        );
      }
    }
  ],
  [
    data.length,
    uiTexts,
    selectedItems,
    selectedItemsCount,
    itemsEmails,
    emailInputIds,
    handleHeaderCheckBoxChange,
    handleCheckBoxChange,
    handleEmailsInputChange,
    handleEmailInputBlur,
    handleTestSendButtonClick
  ]);

  return (
    <Table
      className={classNames(Css.businessesTable, className)}
      columns={tableColumns}
      data={data}
      pageSize={data.length} />
  );
};

export default React.memo(BusinessesTable);
