import Css from "./style.module.scss";

import business from "assets/business.svg";

import { DropDownSelect } from "lib/common";
import { FiPlus } from "react-icons/fi";
import { FormInput } from "shards-react";
import { checkFetchingData } from "selectors/data";
import { getActiveOrganization } from "selectors/organizations";
import { getSelectedBusinessData, getUserBusinessesData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { getUserRestrictions } from "selectors/user";
import { useSelector } from "react-redux";
import BusinessStatus from "nlib/common/BusinessStatus";
import IntegrationServices from "const/IntegrationServices";
import React, { useCallback, useMemo, useState } from "react";

const MIN_BUSINESSES_COUNT_FOR_SEARCH = 10;

const BusinessSelector = ({ onBusinessSelect, onAddNewBusiness }) => {
  const { uiTexts } = useSelector(getTextsData);

  const activeOrganization = useSelector(getActiveOrganization);

  const businessesData = useSelector(getUserBusinessesData);

  const userRestrictions = useSelector(getUserRestrictions);

  const selectedBusinessData = useSelector(getSelectedBusinessData);

  const fetchingData = useSelector(checkFetchingData);

  const [dropDownExpanded, setDropdownExpanded] = useState(false);

  const [searchString, setSearchString] = useState("");

  const options = useMemo(() => {
    const searchStringNormalized = searchString.trim().toLowerCase();

    const businessesOptions = businessesData.map(({ id, name, extraData = {}, settings }) => {
      const {
        logo: serviceLogo,
        label: serviceName
      } = (IntegrationServices.getByValue(extraData.integrationService) || { logo: business, label: uiTexts.business });

      return {
        value: id,
        hidden: searchStringNormalized && !`${name}`.toLowerCase().includes(searchStringNormalized),
        label: (
          <>
            <img title={serviceName} alt={serviceName} src={serviceLogo} />
            <BusinessStatus iconOnly extraData={extraData} settings={settings} />
            <span title={name}>{name}</span>
          </>
        )
      };
    });

    return [
      ...(!userRestrictions.businessesCreate && !activeOrganization.appsumoValue
        ? [{
          value: null,
          unselectable: true,
          hidden: !!searchStringNormalized,
          label: <><FiPlus /><span>{uiTexts.addNewBusiness}</span></>
        }]
        : []),
      ...businessesOptions,
      ...(searchStringNormalized && businessesOptions.every(({ hidden }) => hidden)
        ? [{
          value: null,
          disabled: true,
          label: uiTexts.noResultsFound
        }] : [])
    ];
  }, [
    searchString,
    businessesData,
    userRestrictions.businessesCreate,
    activeOrganization.appsumoValue,
    uiTexts
  ]);

  const handleDropDownToggleState = useCallback((value) => {
    if (!value) setSearchString("");
    setDropdownExpanded(value);
    if (dropDownExpanded) {
      window.document.body.scrollTop = window.document.documentElement.scrollTop = 0;
    }
  }, [dropDownExpanded]);

  const handleBusinessSelectChange = useCallback((businessId) => {
    if (businessId) onBusinessSelect(businessId);
    else onAddNewBusiness();
  }, [onAddNewBusiness, onBusinessSelect]);

  const handleSearchChange = useCallback((event) => {
    setSearchString(event.target.value);
  }, []);

  return (
    <DropDownSelect
      nav
      caret
      disableSelected
      key={businessesData.length}
      disabled={fetchingData}
      className={Css.businessSelector}
      toggleClassName={Css.businessSelectorToggle}
      menuClassName={Css.businessSelectorMenu}
      initialValue={selectedBusinessData.id}
      options={options}
      prepend={(businessesData.length >= MIN_BUSINESSES_COUNT_FOR_SEARCH) && (
        <FormInput
          autoFocus
          className={Css.businessSearch}
          placeholder={uiTexts.searchBusiness}
          value={searchString}
          onChange={handleSearchChange} />
      )}
      onToggleState={handleDropDownToggleState}
      onChange={handleBusinessSelectChange} />
  );
};

export default BusinessSelector;
