import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import { Col, FormCheckbox, FormGroup, Row } from "shards-react";
import { FiBriefcase, FiInfo } from "react-icons/fi";
import { Form, ModalWindow } from "lib/common";
import { getTextsData } from "selectors/texts";
import { getUserBusinessesData } from "selectors/businesses";
import { useSelector } from "react-redux";
import BusinessesTable from "./lib/BusinessesTable";
import IntegrationServices from "const/IntegrationServices";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Utils from "utils/Utils";

const SelectedBusinessesWindow = ({ service, businessesList, onClose }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const userBusinessesData = useSelector(getUserBusinessesData);

  const [showSelectedOnly, setShowSelectedOnly] = useState(false);

  const [selectedItems, setSelectedItem] = useState([]);

  const serviceInfo = useMemo(() => IntegrationServices.getByValue(service), [service]);

  const Icon = useMemo(() => () => <img src={serviceInfo.logo} />, [serviceInfo]);

  const tableData = useMemo(() => {
    return businessesList.map((businessData) => {
      return {
        id: businessData.itemId,
        name: businessData.businessName,
        connected: userBusinessesData.some(({
          extraData: {
            xeroOrganizationId,
            quickBooksRealmId,
            zohoOrganizationId
          } = {}
        }) => {
          return [xeroOrganizationId, quickBooksRealmId, zohoOrganizationId].includes(businessData.itemId);
        })
      };
    }).filter((businessData) => {
      return (showSelectedOnly ? selectedItems.includes(businessData.id) : true);
    }).sort(({ connected: connectedA, name: nameA }, { connected: connectedB, name: nameB }) => {
      if (connectedA && !connectedB) return -1;
      if (!connectedA && connectedB) return 1;

      return nameA.localeCompare(nameB);
    });
  }, [businessesList, userBusinessesData, showSelectedOnly, selectedItems]);

  const handleTableSelectionChange = useCallback((value) => {
    setSelectedItem(value);
  }, []);

  const handleShowSelectedOnlyToggleChange = useCallback(({ target: { checked } }) => {
    setShowSelectedOnly(checked);
  }, []);

  const handleModalClose = useCallback((result) => {
    onClose(result ? selectedItems : null);
  }, [selectedItems, onClose]);

  useEffect(() => {
    setSelectedItem(tableData.filter(({ connected }) => connected).map(({ id }) => id));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showSelectedOnly && !selectedItems.length) setShowSelectedOnly(false);
  }, [showSelectedOnly, selectedItems]);

  return (
    <ModalWindow
      className={Css.selectedBusinessesWindow}
      disabledOkButton={!selectedItems.length}
      config={{
        confirm: true,
        headerText: Utils.replaceTextVars(uiTexts.selectServiceBusinesses, { service: serviceInfo.label }),
        okButtonText: tableData
          .filter(({ id }) => selectedItems.includes(id))
          .every(({ connected }) => connected) ? uiTexts.reconnect : uiTexts.connect
      }}
      iconComponent={Icon}
      onClose={handleModalClose}>
      <Form>
        <FormGroup row>
          <Row form>
            <Col>
              <div>
                <FiInfo />
                <span>{messages.selectIntegrationBusinessesDescription}</span>
              </div>
            </Col>
          </Row>
        </FormGroup>
        <FormGroup row>
          <Row form>
            <Col className={Css.tableColumn}>
              {tableData.length
                ? <div className={Css.tableWrapper}>
                  <BusinessesTable
                    className={Css.businessesTable}
                    data={tableData}
                    selectedItems={selectedItems}
                    onSelectionChange={handleTableSelectionChange} />
                </div>
                : <div className={CommonCss.noDataContent}>
                  <div>
                    <div><FiBriefcase /></div>
                    <div>{uiTexts.noData}</div>
                  </div>
                </div>}
            </Col>
          </Row>
        </FormGroup>
        <FormGroup row>
          <Row form>
            <Col>
              <FormCheckbox
                toggle
                disabled={!selectedItems.length}
                checked={showSelectedOnly}
                className={Css.selectedOnlyToggle}
                onChange={handleShowSelectedOnlyToggleChange}>
                {uiTexts.showSelectedOnly}
              </FormCheckbox>
            </Col>
            <Col className={Css.selectedItemsColumn}>
              <div>{`${uiTexts.selected}: ${selectedItems.length}/${tableData.length}`}</div>
            </Col>
          </Row>
        </FormGroup>
      </Form>
    </ModalWindow>
  );
};

export default React.memo(SelectedBusinessesWindow);
