import { getOpenedModalsData } from "selectors/ui";
import { useDispatch, useSelector } from "react-redux";
import AddBusinessWindow from "lib/windows/AddBusinessWindow";
import AddReasonWindow from "lib/windows/AddReasonWindow";
import AddTagsWindow from "lib/windows/AddTagsWindow";
import AskClientWindow from "lib/windows/AskClientWindow";
import ExecutiveSummaryWindow from "lib/windows/ExecutiveSummaryWindow";
import LinkContactWindow from "lib/windows/LinkContactWindow";
import ModalNames from "const/ModalNames";
import React, { useCallback } from "react";
import SelectedBusinessesWindow from "lib/windows/SelectedBusinessesWindow";
import SetupBackupWindow from "lib/windows/SetupBackupWindow";
import UiActions from "actions/UiActions";

const {
  LINK_CONTACT_WINDOW,
  ADD_TAGS_WINDOW,
  ADD_REASON_WINDOW,
  EXECUTIVE_SUMMARY_WINDOW,
  ADD_BUSINESS_WINDOW,
  SETUP_BACKUP_WINDOW,
  ASK_CLIENT_WINDOW,
  SELECT_BUSINESSES_WINDOW
} = ModalNames;

const getModalComponent = (modalName) => {
  switch (modalName) {
    case SELECT_BUSINESSES_WINDOW:
      return SelectedBusinessesWindow;
    case ASK_CLIENT_WINDOW:
      return AskClientWindow;
    case LINK_CONTACT_WINDOW:
      return LinkContactWindow;
    case ADD_TAGS_WINDOW:
      return AddTagsWindow;
    case ADD_REASON_WINDOW:
      return AddReasonWindow;
    case EXECUTIVE_SUMMARY_WINDOW:
      return ExecutiveSummaryWindow;
    case ADD_BUSINESS_WINDOW:
      return AddBusinessWindow;
    case SETUP_BACKUP_WINDOW:
      return SetupBackupWindow;

    default:
      return null;
  }
};

const ModalsContainer = () => {
  const dispatch = useDispatch();

  const openedModalsData = useSelector(getOpenedModalsData);

  const handleModalClose = useCallback((result) => {
    dispatch(UiActions.hideCustomModal(result));
  }, [dispatch]);

  return openedModalsData.map(({ modalName, ...restData }, index) => {
    const ModalComponent = getModalComponent(modalName);

    return ModalComponent
      ? <ModalComponent
        key={modalName}
        disabled={(index + 1) < openedModalsData.length}
        noOverlay={!!index}
        {...restData}
        onClose={handleModalClose} />
      : null;
  });
};

export default React.memo(ModalsContainer);
