import { Button, ModalFooter } from "shards-react";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useCallback } from "react";

const AskClientWindowFooter = ({
  className,
  showInviteButton,
  disabledButtons,
  disabledAskClientButton,
  disabledInviteButton,
  onClose
}) => {
  const { uiTexts } = useSelector(getTextsData);

  const handleAskClientButtonClick = useCallback(() => {
    onClose({ askClient: true });
  }, [onClose]);

  const handleInviteButtonClick = useCallback(() => {
    onClose({ invite: true });
  }, [onClose]);

  const handleCancelButtonClick = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <ModalFooter className={className}>
      <Button
        theme="warning"
        disabled={disabledButtons || disabledAskClientButton}
        onClick={handleAskClientButtonClick}>
        {uiTexts.askWithMagicLink}
      </Button>
      {showInviteButton && <Button
        theme="primary"
        disabled={disabledButtons || disabledInviteButton}
        onClick={handleInviteButtonClick}>
        {uiTexts.inviteAsBusinessUser}
      </Button>}
      <Button disabled={disabledButtons} theme="secondary" onClick={handleCancelButtonClick}>
        <span>{uiTexts.close}</span>
      </Button>
    </ModalFooter>
  );
};

export default React.memo(AskClientWindowFooter);
