import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import * as Yup from "yup";
import {
  Col,
  FormCheckbox,
  FormGroup,
  FormInput,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Tooltip
} from "shards-react";
import { FiAlertTriangle, FiBriefcase } from "react-icons/fi";
import { Form, ModalWindow } from "lib/common";
import { bind } from "decko";
import { connect } from "react-redux";
import { getActiveOrganization, getProjectName } from "selectors/organizations";
import { getAllBusinessesData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import DataConstants from "const/DataConstants";
import React, { PureComponent } from "react";
import classNames from "classnames";

import { getPricesData } from "selectors/metaData";
import AddBusinessWindowModeSelector, { ADD_BUSINESS_WINDOW_MODES } from "./lib/AddBusinessWindowModeSelector";
import AddNewBusiness from "nlib/common/AddNewBusiness";
import Constants from "const/Constants";
import Utils from "utils/Utils";

const mapStateToProps = (state) => ({
  activeOrganization: getActiveOrganization(state),
  projectName: getProjectName(state),
  textsData: getTextsData(state),
  businessesData: getAllBusinessesData(state),
  pricesData: getPricesData(state)
});

@connect(mapStateToProps)
class AddBusinessWindow extends PureComponent {
  static VALIDATION_SCHEMA = Yup.object().shape({
    type: Yup.string().trim().required(),
    name: Yup.string().trim().required(),
    regId: Yup.string().trim().required(),
    vatPayer: Yup.bool().required()
  });

  constructor(props) {
    super(props);

    const { BY_ID } = ADD_BUSINESS_WINDOW_MODES;

    this.state = {
      selectedTab: BY_ID,
      name: "",
      regId: "",
      type: "",
      vatPayer: false
    };
  }

  get businessPrice() {
    const { activeOrganization, pricesData: { monthlyPrices, annualPrices } } = this.props;

    let price = null;

    if (!activeOrganization.billingId) return null;
    try {
      const prices = activeOrganization.annualBilling ? annualPrices : monthlyPrices;

      price = prices.regular[activeOrganization.currency] || prices.regular[Constants.DEFAULT_CURRENCY];

      return Utils.toMoneyString(
        price,
        prices.regular[activeOrganization.currency] ? activeOrganization.currency : Constants.DEFAULT_CURRENCY,
        price % 1 ? 1 : 0
      );
    } catch (error) {}

    return price;
  }

  checkNameUniqueness(value) {
    return !this.props.businessesData.some(({ name }) => {
      return name && value && name.trim().toLowerCase() === value.trim().toLowerCase();
    });
  }

  checkRegIdUniqueness(value) {
    return !this.props.businessesData.some(({ regId }) => {
      return regId && value && regId.trim().toLowerCase() === value.trim().toLowerCase();
    });
  }

  @bind
  checkFormValidity(state) {
    const { selectedTab, name, regId } = state;

    const regIdMode = selectedTab === ADD_BUSINESS_WINDOW_MODES.BY_ID;

    return regId && this.checkRegIdUniqueness(regId)
      && (regIdMode || (AddBusinessWindow.VALIDATION_SCHEMA.isValidSync(state) && this.checkNameUniqueness(name)));
  }

  @bind
  handleClose(result) {
    const { selectedTab, ...restState } = this.state;

    const regIdMode = selectedTab === ADD_BUSINESS_WINDOW_MODES.BY_ID;

    this.props.onClose(result && (regIdMode ? { regId: this.state.regId } : { ...restState }));
  }

  @bind
  handleModeSelectorChange(selectedTab) {
    this.setState({ selectedTab });
  }

  @bind
  handleNameInputChange({ target: { value } }) {
    this.setState({ name: value });
  }

  @bind
  handleIdInputChange({ target: { value } }) {
    this.setState({ regId: value.replace(/\s/g, "") });
  }

  @bind
  handleTypeSelectChange({ target: { value } }) {
    this.setState({ type: value });
  }

  @bind
  handleVatPayerToggleChange({ target: { checked } }) {
    this.setState({ vatPayer: checked });
  }

  @bind
  handleIntegrationServiceButtonClick({ currentTarget: { dataset: { service } } }) {
    this.props.onClose({
      integrationService: service,
      rpaMode: this.state.selectedTab === ADD_BUSINESS_WINDOW_MODES.RPA_INTEGRATION
    });
  }

  @bind
  handleAddBusiness(data) {
    this.props.onClose(data);
  }

  renderTabs() {
    return (
      <FormGroup row className={CommonCss.flexCenter}>
        <Row>
          <Col>
            <AddBusinessWindowModeSelector
              selectedTab={this.state.selectedTab}
              onChange={this.handleModeSelectorChange} />
          </Col>
        </Row>
      </FormGroup>
    );
  }

  renderByIdModeRows() {
    const { textsData: { uiTexts } } = this.props;

    const { regId } = this.state;

    const uniqueId = this.checkRegIdUniqueness(regId);

    return (
      <>
        {this.businessPrice && <FormGroup row>
          <div>
            <span>
              <b>{`${uiTexts.price}: `}</b>
              <span>{`${this.businessPrice} / ${uiTexts.month.toLowerCase()}`}</span>
            </span>
          </div>
        </FormGroup>}
        <FormGroup row>
          <Row form>
            <Col>
              <InputGroup>
                <InputGroupAddon type="prepend">
                  <InputGroupText>{uiTexts.businessId}</InputGroupText>
                </InputGroupAddon>
                <FormInput
                  id="businessId"
                  placeholder={uiTexts.enterCzechBusinessOrTaxId}
                  value={regId}
                  invalid={!regId}
                  onChange={this.handleIdInputChange} />
                <Tooltip open={!uniqueId} target="#businessId">
                  <b className={CommonCss.negativeText}>
                    <FiAlertTriangle />
                    <span>{uiTexts.idIsNotUnique}</span>
                  </b>
                </Tooltip>
              </InputGroup>
            </Col>
          </Row>
        </FormGroup>
      </>
    );
  }

  renderManualModeRows() {
    const { LEGAL_PERSON, NATURAL_PERSON } = DataConstants.CONTACT_TYPES;

    const { textsData: { uiTexts } } = this.props;

    const { name, regId, type, vatPayer } = this.state;

    const uniqueName = this.checkNameUniqueness(name);

    const uniqueId = this.checkRegIdUniqueness(regId);

    const contactTypesList = [
      { value: LEGAL_PERSON, label: uiTexts.legalPerson },
      { value: NATURAL_PERSON, label: uiTexts.naturalPerson }
    ];

    return (
      <>
        {this.businessPrice && <FormGroup row>
          <div>
            <span>
              <b>{`${uiTexts.price}: `}</b>
              <span>{`${this.businessPrice} / ${uiTexts.month.toLowerCase()}`}</span>
            </span>
          </div>
        </FormGroup>}
        <FormGroup row>
          <Row form>
            <Col>
              <InputGroup>
                <InputGroupAddon type="prepend">
                  <InputGroupText><span>{uiTexts.name}</span></InputGroupText>
                </InputGroupAddon>
                <FormInput
                  id="businessName"
                  placeholder={uiTexts.enterBusinessName}
                  value={name}
                  invalid={!name}
                  onChange={this.handleNameInputChange} />
                <Tooltip open={!uniqueName} target="#businessName">
                  <b className={CommonCss.negativeText}>
                    <FiAlertTriangle />
                    <span>{uiTexts.nameIsNotUnique}</span>
                  </b>
                </Tooltip>
              </InputGroup>
            </Col>
          </Row>
          <Row form>
            <Col>
              <InputGroup>
                <InputGroupAddon type="prepend">
                  <InputGroupText><span>{uiTexts.businessId}</span></InputGroupText>
                </InputGroupAddon>
                <FormInput
                  id="businessId"
                  placeholder={uiTexts.enterBusinessId}
                  value={regId}
                  invalid={!regId}
                  onChange={this.handleIdInputChange} />
                <Tooltip open={!uniqueId} target="#businessId">
                  <b className={CommonCss.negativeText}>
                    <FiAlertTriangle />
                    <span>{uiTexts.idIsNotUnique}</span>
                  </b>
                </Tooltip>
              </InputGroup>
            </Col>
          </Row>
          <Row form>
            <Col>
              <InputGroup>
                <InputGroupAddon type="prepend">
                  <InputGroupText><span>{uiTexts.type}</span></InputGroupText>
                </InputGroupAddon>
                <FormSelect
                  placeholder={type ? undefined : ""}
                  value={type}
                  invalid={!type}
                  onChange={this.handleTypeSelectChange}>
                  {!type && <option value="">{uiTexts.selectType}</option>}
                  {contactTypesList.map(({ value, label }) => {
                    return <option key={value} value={value}>{label}</option>;
                  })}
                </FormSelect>
              </InputGroup>
            </Col>
          </Row>
          <Row form>
            <Col>
              <FormCheckbox
                toggle
                checked={vatPayer}
                className={Css.vatPayerToggle}
                onChange={this.handleVatPayerToggleChange}>{uiTexts.vatPayer}</FormCheckbox>
            </Col>
          </Row>
        </FormGroup>
      </>
    );
  }

  renderIntegrationModeRows() {
    return <AddNewBusiness onAddBusiness={this.handleAddBusiness} />;
  }

  render() {
    const { BY_ID, MANUAL, INTEGRATION } = ADD_BUSINESS_WINDOW_MODES;

    const { textsData: { uiTexts } } = this.props;

    const { selectedTab } = this.state;

    const renderEditModeBlock = {
      [BY_ID]: () => this.renderByIdModeRows(),
      [MANUAL]: () => this.renderManualModeRows(),
      [INTEGRATION]: () => this.renderIntegrationModeRows()
    }[selectedTab];

    return (
      <ModalWindow
        applyOnEnterPress
        className={classNames(
          Css.addBusinessWindow,
          selectedTab === INTEGRATION && Css.integration
        )}
        bodyClassName={Css.bodyClassName}
        disabledOkButton={!this.checkFormValidity(this.state)}
        config={{
          confirm: true,
          headerText: uiTexts.addNewBusiness,
          okButtonText: uiTexts.addBusiness
        }}
        iconComponent={FiBriefcase}
        onClose={this.handleClose}>
        <Form>
          {this.renderTabs()}
          {renderEditModeBlock()}
        </Form>
      </ModalWindow>
    );
  }
}

export default AddBusinessWindow;
