import CommonCss from "lib/common/style.module.scss";

import TableCss from "lib/common/Table/style.module.scss";

import { FiLink } from "react-icons/fi";
import { FormCheckbox } from "shards-react";
import { Table } from "lib/common";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useCallback, useMemo } from "react";

const SQUARE_CELL_WIDTH = 50;

const BusinessesTable = ({
  data,
  selectedItems,
  className,
  onSelectionChange
}) => {
  const { uiTexts } = useSelector(getTextsData);

  const handleHeaderCheckBoxChange = useCallback(({ target: { checked } }) => {
    onSelectionChange(checked ? data.map(({ id }) => id) : data.filter(({ connected }) => connected).map(({ id }) => id));
  }, [data, onSelectionChange]);

  const handleCheckBoxChange = useCallback(({ target: { checked, dataset: { id } } }) => {
    onSelectionChange(checked ? [...selectedItems, id] : selectedItems.filter((selectedItem) => selectedItem !== id));
  }, [selectedItems, onSelectionChange]);

  const tableColumns = useMemo(() => [
    {
      accessor: "id",
      sortable: false,
      width: SQUARE_CELL_WIDTH,
      className: TableCss.squareCell,
      headerClassName: TableCss.squareCell,
      Header: () => {
        return <FormCheckbox checked={data.length === selectedItems.length} onChange={handleHeaderCheckBoxChange} />;
      },
      Cell: ({ value }) => {
        return (
          <FormCheckbox
            data-id={value}
            checked={selectedItems.includes(value)}
            disabled={data.find(({ id }) => id === value).connected}
            onChange={handleCheckBoxChange} />
        );
      }
    },
    {
      accessor: "name",
      Header: uiTexts.businessName,
      Cell: ({ value, original: { connected } }) => {
        return (
          <span>
            <FiLink className={connected ? CommonCss.positiveText : CommonCss.mediumDarkText} />
            <span>{connected ? <b>{value}</b> : value}</span>
          </span>
        );
      }
    }
  ],
  [
    data,
    uiTexts,
    selectedItems,
    handleHeaderCheckBoxChange,
    handleCheckBoxChange
  ]);

  return (
    <Table
      className={className}
      columns={tableColumns}
      data={data}
      pageSize={data.length} />
  );
};

export default React.memo(BusinessesTable);
