import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import * as Yup from "yup";
import { Col, FormGroup, FormInput, InputGroup, InputGroupAddon, InputGroupText, Row, Tooltip } from "shards-react";
import { FiAlertTriangle, FiBriefcase, FiInfo, FiUser, FiUsers } from "react-icons/fi";
import { Form, ModalWindow } from "lib/common";
import { getActiveOrganization, getAllUsersData } from "selectors/organizations";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import AskClientWindowFooter from "./lib/AskClientWindowFooter";
import Constants from "const/Constants";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import classNames from "classnames";

const EMAIL_VALIDATION_SCHEMA = Yup.object().shape({
  userEmail: Yup.string().trim().email().required()
});

const PHONE_VALIDATION_SCHEMA = Yup.object().shape({
  userPhone: Yup.string().trim().matches(Constants.PHONE_INPUT_PATTERN)
});

const AskClientWindow = ({ needReactionTranactionsCount, noPhone, onClose }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const usersData = useSelector(getAllUsersData);

  const activeOrganization = useSelector(getActiveOrganization);

  const selectedBusinessData = useSelector(getSelectedBusinessData);

  const smsNotificationsAllowed = Constants.SMS_NOTIFICATIONS_ALLOWED_COUNTRIES
    .includes(activeOrganization.countryCode);

  const [userEmail, setUserEmail] = useState("");

  const [userPhone, setUserPhone] = useState("");

  const accountantUsersData = useMemo(() => {
    return usersData.filter(({ role }) => {
      return UserRoles.checkIsAccountant(role);
    });
  }, [usersData]);

  const businessUsersData = useMemo(() => {
    return usersData.filter(({ role, guestUser, businessIds }) => {
      return UserRoles.checkIsBusiness(role) && !guestUser
        && (!businessIds.length || businessIds.includes(selectedBusinessData.id));
    });
  }, [usersData, selectedBusinessData]);

  const guestUsersData = useMemo(() => {
    return usersData.filter(({ role, guestUser, businessIds }) => {
      return UserRoles.checkIsBusiness(role) && guestUser
        && (!businessIds.length || businessIds.includes(selectedBusinessData.id));
    });
  }, [usersData, selectedBusinessData]);

  const accountantUserEmail = useMemo(() => {
    return accountantUsersData.some(({ email }) => {
      return email && userEmail && email.trim().toLowerCase() === userEmail.trim().toLowerCase();
    });
  }, [userEmail, accountantUsersData]);

  const uniqueBusinessUserEmail = useMemo(() => {
    return !businessUsersData.some(({ email }) => {
      return email && userEmail && email.trim().toLowerCase() === userEmail.trim().toLowerCase();
    });
  }, [userEmail, businessUsersData]);

  const validEmail = useMemo(() => {
    return EMAIL_VALIDATION_SCHEMA.isValidSync({ userEmail }) && !accountantUserEmail && uniqueBusinessUserEmail;
  }, [userEmail, accountantUserEmail, uniqueBusinessUserEmail]);

  const validPhone = useMemo(() => {
    return !userPhone || ((PHONE_VALIDATION_SCHEMA.isValidSync({ userPhone })) && !!Utils.normalizePhoneNumber(userPhone));
  }, [userPhone]);

  const handleEmailInputChange = useCallback(({ target: { value } }) => {
    setUserEmail(value);
  }, []);

  const handlePhoneInputChange = useCallback(({ target: { value } }) => {
    setUserPhone(value);
  }, []);

  const handleGuestEmailLinkClock = useCallback(({ target: { dataset: { value } } }) => {
    setUserEmail(value);
  }, []);

  const handleClose = useCallback(({ askClient, invite } = {}) => {
    if (askClient || invite) onClose({ userEmail, userPhone }, !!invite);
    else onClose();
  }, [userEmail, userPhone, onClose]);

  const businessUserNames = useMemo(() => {
    return businessUsersData.map((user, index) => {
      return (
        <Fragment key={user.id}>
          <span>{user.fullName || user.email}</span>
          {index !== businessUsersData.length - 1 && <span>, </span>}
        </Fragment>
      );
    });
  }, [businessUsersData]);

  const guestUserNames = useMemo(() => {
    return guestUsersData.map((user, index) => {
      return (
        <Fragment key={user.id}>
          <a data-value={user.email} onClick={handleGuestEmailLinkClock}>{user.email}</a>
          {index !== guestUsersData.length - 1 && <span>, </span>}
        </Fragment>
      );
    });
  }, [guestUsersData, handleGuestEmailLinkClock]);

  const footerProps = useMemo(() => ({
    disabledAskClientButton: !validEmail || !validPhone,
    disabledInviteButton: !validEmail || !validPhone,
    showInviteButton: !activeOrganization.guestClientsOnly,
    onClose: handleClose
  }), [activeOrganization, validEmail, validPhone, handleClose]);

  return (
    <ModalWindow
      className={Css.askClientWindow}
      config={{ headerText: uiTexts.inviteUser }}
      iconComponent={FiUser}
      footerComponent={AskClientWindowFooter}
      footerProps={footerProps}
      onClose={onClose}>
      <Form>
        {!!needReactionTranactionsCount && (
          <FormGroup row>
            <div>
              <FiInfo className={CommonCss.warningText} />
              <span>
                <b>
                  {Utils.replaceTextVars(
                    messages.transactionsToClarify,
                    { transactionsCount: needReactionTranactionsCount }
                  )}
                </b>
              </span>
            </div>
            <div>{smsNotificationsAllowed ? messages.askClientDescription : messages.askClientByEmailDescription}</div>
          </FormGroup>
        )}
        <FormGroup row>
          <div>
            <FiBriefcase />
            <span>
              <b>{`${uiTexts.businessName}: `}</b>
              <span>{selectedBusinessData.name}</span>
            </span>
          </div>
        </FormGroup>
        {!!businessUserNames.length && <FormGroup row>
          <div>
            <FiUsers />
            <span>
              <b>{`${uiTexts.businessUsers}: `}</b>
              {businessUserNames}
            </span>
          </div>
        </FormGroup>}
        {!!guestUserNames.length && <FormGroup row>
          <div>
            <FiUsers />
            <span>
              <b>{`${uiTexts.guestUsers}: `}</b>
              {guestUserNames}
            </span>
          </div>
        </FormGroup>}
        <FormGroup row>
          <Row form>
            <Col>
              <InputGroup>
                <InputGroupAddon type="prepend">
                  <InputGroupText>{uiTexts.email}</InputGroupText>
                </InputGroupAddon>
                <FormInput
                  id="askClientEmail"
                  type="email"
                  placeholder={uiTexts.enterEmail}
                  invalid={!validEmail}
                  value={userEmail}
                  onChange={handleEmailInputChange} />
                <Tooltip open={accountantUserEmail} target="#askClientEmail">
                  <div className={classNames(Css.tooltip, CommonCss.negativeText)}>
                    <FiAlertTriangle />
                    <span>{messages.userIsAccountant}</span>
                  </div>
                </Tooltip>
                <Tooltip open={!uniqueBusinessUserEmail} target="#askClientEmail">
                  <div className={classNames(Css.tooltip, CommonCss.negativeText)}>
                    <FiAlertTriangle />
                    <span>{messages.userAlreadyMember}</span>
                  </div>
                </Tooltip>
              </InputGroup>
            </Col>
          </Row>
        </FormGroup>
        {smsNotificationsAllowed && !noPhone && (
          <>
            <FormGroup row>
              <div>{messages.askClientPhone}</div>
            </FormGroup>
            <FormGroup row>
              <Row form>
                <Col>
                  <InputGroup>
                    <InputGroupAddon type="prepend">
                      <InputGroupText>{uiTexts.phone}</InputGroupText>
                    </InputGroupAddon>
                    <FormInput
                      id="askClientPhone"
                      type="tel"
                      placeholder={uiTexts.enterPhoneNumber}
                      invalid={!validPhone}
                      value={userPhone}
                      onChange={handlePhoneInputChange} />
                  </InputGroup>
                </Col>
              </Row>
            </FormGroup>
          </>
        )}
      </Form>
    </ModalWindow>
  );
};

export default React.memo(AskClientWindow);
